import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-howling-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1981 werewolf movie, The Howling"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 06</h1>
                    <h2>The Howling</h2>
                    <h3>May 1, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Joe Dante // <b>Starring:</b> Dee Wallace &amp; Patrick MacNee</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/06-The-Howling-e23ad27" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 6, The Howling"></iframe>
                        </div>
                        <p><Link to="/transcripts/the-howling">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave break down one of the four werewolf movies released in 1981, Joe Dante's iconic The Howling. It's a wild ride made notable by its awesome special effects and dark comedic tone. And it's a treat for fans of of classic horror movies with cameos from Roger Corman and Forrest Ackerman, not to mention dozens of references to other werewolf movies. It's one of the all-time greats.</p>
                        <p>Trailer: <OutboundLink href="https://www.youtube.com/watch?v=pZARVBpjC1I" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=pZARVBpjC1I</OutboundLink></p>
                        <p>Trailers From Hell, with Josh Olson: <OutboundLink href="https://trailersfromhell.com/howling/" target="_blank" rel="noreferrer">https://trailersfromhell.com/howling/</OutboundLink></p>
                        <p>Horror's Hallowed Grounds with Sean Clark: <OutboundLink href="https://www.youtube.com/watch?v=temUGpwfmjc" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=temUGpwfmjc</OutboundLink></p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/final-exam">Next Episode</Link></li>
                        <li><Link to="/episodes/the-sentinel">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)